.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.set-bg-img{
  background-image: url(../public/svgs/Frame\ 133.svg);
  background-repeat: repeat-x;
  background-position: center bottom;
}

/* .checkbox {
  display: flex;
  cursor: pointer;
  position: relative;
} */

input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=checkbox]:after {
  content: url("../public/svgs/check.svg");
  display: none;
  margin-bottom: 4px;
}

input[type=checkbox]:checked {
  background-color: #6FA7B4;
  border-color: #6FA7B4;
}

input[type=checkbox]:checked:after {
  display: block;
}

.bg-modal2 {
  background-color: rgba(0, 0, 0, 0.21);
}

.css-ndtfpb-control, .css-12b3jm2-control {
  outline: none !important;
  border-color: #DADAE7 !important;
}

.largeselect .css-319lph-ValueContainer {
  padding: 0px !important;
}